import React from 'react';
import { Link } from "react-router-dom";
import logo from '../../imgs/logo/logo_color.png';
import payment from '../../imgs/visa_list.png';

const BigFooter = () => {
  return (
    <footer className="desktop_footer">
     <div className="container">
       <div className="row">
        <div className="col-3 footer-contact">
          
          <p className="font-19 golden-color mb-0"><b>Compnay Info</b></p>
          <p className="text-golden mt-10">
            <Link to="../story" className="text-golden">Our Story</Link><br />
            <Link to="../login" className="text-golden">Wishlist</Link><br />
            <Link to="../login" className="text-golden">My Account</Link><br />
            <Link to="../shipping" className="text-golden">Shipping & Delivery</Link>
          </p>
        </div>
        <div className="col-3 footer-contact">
          
          <p className="font-19 golden-color mb-0"><b>Customer Service</b></p>
          <p className="text-golden mt-10">
            <Link to="../contact" className="text-golden">Contact Us</Link><br />
            <Link to="../terms" className="text-golden">Terms & Conditions</Link><br />
            <Link to="../privacy" className="text-golden">Privacy Policy</Link><br />
            <Link to="../returns" className="text-golden">Returns & Refund</Link>
          </p>
         
          
        </div>
        <div className="col-3 footer-contact">
          
          <p className="font-19 golden-color mb-0"><b>Get In Touch</b></p>
          <p className="text-golden mt-10">
                   <a tel="+919799332768" className="text-golden">
                    <i className="bi bi-phone"></i>   +91-97993 32768
                    </a>
                  
                    <br />
                    <a tel="+919950039573" className="text-golden">
                    <i className="bi bi-phone"></i>  +91-99500 39573
                    </a> <br />  
                    <Link to="" className="text-golden"><i className="bi bi-envelope"></i>&nbsp; info@codesilver.in</Link>
                    <br /> 
                    <Link to="/Tracking" className="text-golden"><i className="bi bi-truck"></i>&nbsp; Tracking</Link>
          </p>
        </div>
        <div className="col-3 footer-contact">
          <div className="row p-0">
          <p className="font-19 golden-color mb-0 p-0"><b>Subscribe</b></p>
          <input type="text" placeholder="Your Email" className="subscribe-box" />
          <button className="subscribe-btn">Subscribe</button>

          </div>
         
          <div className="row p-0 footer_links_social">
            <a href="https://www.facebook.com/Code-Silver-India-100517178515401" target="_blank">  <i className="bi bi-facebook"></i></a>
            <a href="https://www.instagram.com/codesilverindia/" target="_blank"> <i className="bi bi-instagram"></i></a>
            <a href="https://twitter.com/codesilverwild/" target="_blank">  <i className="bi bi-twitter"></i></a>
         
          </div>
         
        </div>
      </div>
      <div className="divider mtb-10"></div>
      <div className="row d-flex align-items-center">
        <div className="col-6"><p className="mtb-0 text-golden font-11">Copyright &copy; 2022 Codesilver</p></div>
        <div className="col-6">
          <img src={payment} alt="" className="img-fluid float-right pay-links" />
        </div>
      </div>
    </div>
    </footer>
  )
}

export default BigFooter