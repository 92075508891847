import React, { useContext, useState, useEffect } from "react";
import Header from "../../common/Header";
import FooterDesktop from "../../common/FooterDesktop";
import { Link, useNavigate } from "react-router-dom";
import CartItem from "./CartItem";
import CartTotal from "./CartTotal";
import { CartContext, CatalogContext, SessionContext } from "../../../contexts";
import Billing from "../checkout/Billing";
import Loader from "../../common/Loader";
import EmptyCart from "./EmptyCart";
import DesktopCart from "./DesktopCart";
import MobileCart from "./MobileCart";


const Cart = () => {
  const { cart, updateCart, addCoupon, deleteFromCart, updateQuantity, validateCheckout } =
    useContext(CartContext);

  const { currency } = useContext(SessionContext);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const navigate = useNavigate();
  const [isDesktop, setDesktop] = useState(window.innerWidth > 650);
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  const updateMedia = () => {
    setDesktop(window.innerWidth > 765);
  };

  const handleCheckout = async () => {
    setButtonsDisabled(true);
    navigate("/checkout");
  };
  return (
    <div>
      <Header />
      <section className="mh-70">
        <div className="container mt-100 mb-30">
          <div className="row text-center ">
            <h1 className="mtb-20">Shopping Cart</h1>
          </div>

          {cart ? (
            cart.length !== 0 ? (
              isDesktop ? (
                <DesktopCart
                  cart={cart}
                  updateQuantity={updateQuantity}
                  deleteFromCart={deleteFromCart}
                />
              ) : (
                <MobileCart
                  cart={cart}
                  updateQuantity={updateQuantity}
                  deleteFromCart={deleteFromCart}
                />
              )
            ) : (
              <EmptyCart />
            )
          ) : (
            <Loader />
          )}
          {cart && cart.length !== 0 ? (
         <>
           <CartTotal
              cart={cart}
              handleCheckout={handleCheckout}
              buttonsDisabled={buttonsDisabled}
              addCoupon={addCoupon}
            />
          </>  
          ) : null}
        </div>
      </section>
      <FooterDesktop />
    </div>
  );
};

export default Cart;
