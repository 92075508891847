const QuantityInputButtons = (props) => {
  return (
    <span className="counter-group d-flex align-items-center">
      <button
        className="btn btn-counter fl"
        onClick={(e) => props.handleIncrement(e)}
      >
        <i className="bi bi-plus font-16  text-gold"></i>
      </button>
      <p className="count mb-0 fl font-16 mlr-15 text-gold">
        {Number(props.quantity).toString()}
      </p>
      <button
        className="btn btn-counter fl"
        onClick={(e) => props.handleDecrement(e)}
      >
        <i className="bi bi-dash font-16  text-gold"></i>
      </button>
    </span>
  );
};

export default QuantityInputButtons;
