import React, { useState, useEffect } from "react";
import HeaderDemo from "../common/HeaderDemo";
import SliderTop from "./slider/SliderTop";
import SliderTopMobile from "../home/slider/SliderTopMobile";

import Bareware from "../home/Bareware";
import BarewareMobile from "../home/BarewareMobile";
import Behind from "../home/behind/Behind";

import HomeDecorMobile from "../home/HomeDecorMobile";
import HomeDecorDemo from "./HomeDecorDemo";

import FooterDesktop from "../common/FooterDesktop";
import Curtain from "../common/Curtain";

const HomeDemo = () => {
  const [isLoaded, setLoaded] = useState(0);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 650);
  const updateMedia = () => {
    setDesktop(window.innerWidth > 765);
  };
  useEffect(() => {
    setTimeout(function () {
      setLoaded(1);
    }, 2000);
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  return (
    <div>
      <HeaderDemo />
     
      {isDesktop ? <SliderTop /> : <SliderTopMobile />}

      {isDesktop ? <HomeDecorDemo /> : <HomeDecorMobile />}

    

    
      <FooterDesktop page="/" />
    </div>
  );
};

export default HomeDemo;
