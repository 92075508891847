import React from "react";
import upi from "./UPI.png";
import PaymentForm from "./PaymentForm";
import PayPalForm from "./PayPalForm";

import { useContext } from "react";
import { SessionContext } from "../../../contexts";

const PaymentMethod = ({
  payPalForm,
  payUForm,
  handlePaymentChange,
  setPayUForm,
  payment,
}) => {
  const { currency } = useContext(SessionContext);
  const isUS = currency === "USD";
  // console.log(isUS, currency, payment);
  return (
    <div className="paymentbox ">
      <div className="row">
        <div className="col-12">
          <p className="mt-30 mb-10 font-600 text-description font-18">
            Choose Payment Method
          </p>
        </div>
        <div className="row">
          <div className="col-6">
            <div
              onClick={(e) => {
                handlePaymentChange("DC", payUForm, setPayUForm);
              }}
              className={`btn btn-border payment-btn w-100 ${
                payUForm.Pg === "DC" ? "payment-active" : ""
              }`}
            >
              <p className="text-description font-600 font-16 mb-0">
                <i className="bi bi-credit-card-2-back"></i> &nbsp; Debit Card
              </p>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="Pg"
                  id="Pg1"
                  value="DC"
                  checked={payUForm.Pg === "DC"}
                />
              </div>
            </div>
          </div>
          <div className="col-6">
            <div
              onClick={(e) => {
                handlePaymentChange("CC", payUForm, setPayUForm);
              }}
              className={`btn btn-border payment-btn w-100 ${
                payUForm.Pg === "CC" ? "payment-active" : ""
              }`}
            >
              <p className="text-description font-600 font-16 mb-0">
                <i className="bi bi-credit-card-fill"></i> &nbsp; Credit Card
              </p>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="Pg"
                  id="Pg1"
                  value="CC"
                  checked={payUForm.Pg === "CC"}
                  onChange={(e) => {
                    handlePaymentChange(e, payUForm, setPayUForm);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6 mt-20">
            <div
              onClick={(e) => {
                handlePaymentChange("NB", payUForm, setPayUForm);
              }}
              className={`btn btn-border payment-btn w-100 ${
                payUForm.Pg === "NB" ? "payment-active" : ""
              }`}
            >
              <p className="text-description font-600 font-16 mb-0">
                <i className="bi bi-bank"></i> &nbsp; Net Banking
              </p>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="Pg"
                  id="Pg1"
                  value="NB"
                  checked={payUForm.Pg === "NB"}
                  onChange={(e) => {
                    handlePaymentChange(e, payUForm, setPayUForm);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-6 mt-20">
            <div
              onClick={(e) => {
                handlePaymentChange("UPI", payUForm, setPayUForm);
              }}
              className={`btn btn-border payment-btn w-100 ${
                payUForm.Pg === "UPI" ? "payment-active" : ""
              }`}
            >
              <p className="text-description font-600 font-16 mb-0">
                <img src={upi} alt="" className="upi-icon" /> &nbsp; UPI
              </p>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="Pg"
                  id="Pg1"
                  value="UPI"
                  checked={payUForm.Pg === "UPI"}
                  onChange={(e) => {
                    handlePaymentChange(e, payUForm, setPayUForm);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6 mt-20">
            <div
              onClick={(e) => {
                if (isUS) {
                  handlePaymentChange("OT", payUForm, setPayUForm);
                }
              }}
              className={`btn btn-border payment-btn w-100 ${
                payUForm.Pg === "PP" ? "payment-active" : ""
              }`}
            >
              <p className="text-description font-600 font-16 mb-0">
                <i className="bi bi-cash"></i> &nbsp; PayPal
              </p>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="Pg"
                  id="Pg1"
                  value="PP"
                  checked={payUForm.Pg === "PP"}
                  disabled={!isUS}
                  onChange={(e) => {
                    if (isUS) {
                      handlePaymentChange(e, payUForm, setPayUForm);
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-6 mt-20">
            <div
              onClick={(e) => {
                handlePaymentChange("OT", payUForm, setPayUForm);
              }}
              className={`btn btn-border payment-btn w-100 ${
                payUForm.Pg === "OT" ? "payment-active" : ""
              }`}
            >
              <p className="text-description font-600 font-16 mb-0">
                <i className="bi bi-wallet2"></i> &nbsp; Others
              </p>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="Pg"
                  id="Pg1"
                  value="OT"
                  checked={payUForm.Pg === "OT"}
                  onChange={(e) => {
                    handlePaymentChange(e, payUForm, setPayUForm);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isUS ? <PayPalForm payPalForm={payPalForm} /> : null}
      <PaymentForm payUForm={{ payUForm }} />
    </div>
  );
};

export default PaymentMethod;
