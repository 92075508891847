import React from "react";
import CartItem from "./CartItem";

const DesktopCart = ({ cart = [], updateQuantity, deleteFromCart }) => {
  // console.log(cart);
  return (
    <div className="row">
      <table className="table table-stripped mt-30">
        <thead className="cart-table-header">
          <tr>
            <td className="text-center">
              <p>Image</p>
            </td>
            <td className="text-center">
              <p>Name</p>
            </td>
            <td className="text-center">
              <p>Quantity</p>
            </td>
            <td className="text-center">
              <p>Price</p>
            </td>
            <td className="text-center">
              <p>Total</p>
            </td>
            <td className="text-center">
              <p>Remove</p>
            </td>
          </tr>
        </thead>
        <tbody>
          {cart.map((cartItem, index) => {
            return (
              <CartItem
                key={
                  cartItem.product_id +
                  cartItem.price +
                  cartItem.quantity +
                  cartItem.total +
                  index
                }
                cartItem={cartItem}
                updateQuantity={updateQuantity}
                deleteFromCart={deleteFromCart}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DesktopCart;
