import React, { useState, useContext, useEffect } from "react";
import Header from "../common/Header";
import FooterDesktop from "../common/FooterDesktop";
import { Link, useNavigate } from "react-router-dom";
import { CatalogContext, UserContext } from "../../contexts";
import Loading from "../common/Loading";

const Reset = () => {
  const { userDetails, log, user, updateProfile, updatePassword } =
    useContext(UserContext);
  const { showToast } = useContext(CatalogContext);

  const [userForm, setUserForm] = useState({ password: "", confirm: "" });
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const navigate = useNavigate();
  const handleChange = (e) => {
    const form = { ...userForm };
    form[e.target.name] = e.target.value;
    setUserForm(form);
  };

  const updatePass = async () => {
    setButtonsDisabled(true);

    const keys = Object.keys(userForm);
    for (let i = 0; i < keys.length; i++) {
      if (userForm[keys[i]] === "") {
        showToast("error", `${keys[i]} is required!`);

        setButtonsDisabled(false);
        return false;
      }
    }

    const response = await updatePassword(userForm);
    // console.log("response", response);
    if (response && "success" in response && response.success === 1) {
      // console.log("user password updated", userForm);
      showToast("success", "Password updated successfully!");
      setButtonsDisabled(false);
    } else {
      setButtonsDisabled(false);
      // console.log("update failed!", response);
      showToast("error", response.error[0]);
    }
  };

  useEffect(() => {
    if (log === 1 && user && userDetails) {
      // console.log(userDetails);
    }
    if (log == 0) {
      navigate("/login");
    }
  }, [log, user, userDetails]);
  return (
    <div>
      <Header />
      {user && userDetails && userForm && log === 1 ? (
        <div className="section mh-70">
          <div className="container">
            <div className="row">
              <div className="login-card text-center login-box">
                <h1 className="mt-20">Reset Password</h1>
                <div className="col-12">
                  <input
                    value={userForm.password}
                    name="password"
                    onChange={(e) => handleChange(e)}
                    type="password"
                    placeholder="New Password"
                    autocomplete="off"
                    className="form-control "
                  />

                  <input
                    value={userForm.confirm}
                    name="confirm"
                    onChange={(e) => handleChange(e)}
                    type="password"
                    placeholder="Confrim Password"
                    autocomplete="off"
                    className="form-control "
                  />

                  <span>
                    <button
                      onClick={updatePass}
                      disabled={buttonsDisabled}
                      id="button-guest"
                      data-loading-text="Loading..."
                      className="btn btn-primary btn-gold w-100"
                    >
                      {buttonsDisabled ? "Updating" : "Reset"}
                    </button>
                  </span>
                  <br />
                  <Link to="../account" className="back_btn text-description">
                    {" "}
                    <i className="bi bi-arrow-left-circle-fill"></i> Go Back
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loading />
      )}

      <FooterDesktop page="/profile" />
    </div>
  );
};

export default Reset;
