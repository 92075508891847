import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import { Home, HomeDemo, Aboutus, Behind, Categories, Product, Story, AllCategories, Search} from './Components';
import { Terms, Privacy, Contact, Return } from './Components';
import { Cart, Billing, Confirm, Checkout, ThankYou, PaymentFailed} from './Components';
import {Login, Forget, Register, Account, EditProfile, Reset, Wishlist} from './Components';
import {AddressBook, AddAddress, EditAddress } from './Components';
import {OrderHistory, PendingOrders, OrderInfo, Shipping } from './Components';
import Toast from './components/toasts/Toast';
import Tracking from './components/common/Tracking';



import ScrollToTop from './components/ScrollToTop';
import { useContext } from 'react';
import { CatalogContext } from './contexts';



function App() {
  const{toastStatus} = useContext(CatalogContext)
  return (
    <div className="App" id="page">
    <Toast props={toastStatus}/>
     <BrowserRouter>
     <ScrollToTop />
      <Routes>
          <Route path='/homedemo' exact element={<HomeDemo />} /> 
          <Route path='/' exact element={<Home />} /> 
          <Route path='/homedemo' exact element={<HomeDemo />} />  

          <Route path='/aboutus' exact element={<Aboutus />} />
          <Route path='/behind' exact element={<Behind />} />
          <Route path='/story' exact element={<Story />} />

          <Route path='/contact' exact element={<Contact />} />
          <Route path='/terms' exact element={<Terms />} />
          <Route path='/Privacy' exact element={<Privacy />} />
          <Route path='/Returns' exact element={<Return />} />
          <Route path='/Tracking' exact element={<Tracking />} />
          <Route path='/Shipping' exact element={<Shipping />} />


          <Route path='/categories/:id' exact element={<Categories />} />
          <Route path='/allcategories' exact element={<AllCategories />} />
          <Route path='/product/:id' exact element={<Product />} />
          <Route path='/search/:search' exact element={<Search />} />


          <Route path='/cart' exact element={<Cart />} />
          <Route path='/Billing' exact element={<Billing />} />
          <Route path='/Confirm' exact element={<Confirm />} />
          <Route path='/Checkout' exact element={<Checkout />} />
          <Route path='/thankYou' exact element={<ThankYou/>} />
          <Route path='/paymentFailed' exact element={<PaymentFailed/>} />


          <Route path='/Login' exact element={<Login />} />
          <Route path='/Forget' exact element={<Forget />} />
          <Route path='/Register' exact element={<Register />} />
          <Route path='/Reset' exact element={<Reset />} />
          <Route path='/Wishlist' exact element={<Wishlist />} />

          <Route path='/Account' exact element={<Account />} />
          <Route path='/EditProfile' exact element={<EditProfile />} />
          <Route path='/addressBook' exact element={<AddressBook/>} />
          <Route path='/addAddress' exact element={<AddAddress/>} />
          <Route path='/editAddress/:id' exact element={<EditAddress/>} />
          <Route path='/editAddress' exact element={<Navigate replace to="/addressBook" />} />

          <Route path='/orderHistory' exact element={<OrderHistory/>} />
          <Route path='/PendingOrders' exact element={<PendingOrders />} />
          <Route path='/OrderInfo/:id' exact element={<OrderInfo />} />

          



          

          <Route path='*' exact element={<Navigate replace to="/" />}/>
      </Routes>
      
     </BrowserRouter>
   
    </div>
  );
}

export default App;
