import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../common/Header";
import FooterDesktop from "../common/FooterDesktop";
import { Link } from "react-router-dom";
import { CatalogContext, UserContext } from "../../contexts";
import Loading from "../common/Loading";

const Login = () => {
  const { user, login, log } = useContext(UserContext);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const { showToast } = useContext(CatalogContext);

  const [loginForm, setLoginForm] = useState({ email: "", password: "" });

  const navigate = useNavigate();
  useEffect(() => {
    if (log === 1) {
      navigate("/account");
    }
  }, [user, log]);

  const handleChange = (e) => {
    const form = { ...loginForm };
    form[e.target.name] = e.target.value;
    setLoginForm(form);
  };

  const loginUser = async () => {
    setButtonsDisabled(true);
    const keys = Object.keys(loginForm);
    for (let i = 0; i < keys.length; i++) {
      if (loginForm[keys[i]] === "") {
        showToast("error", `${keys[i]} is required!`);
        setButtonsDisabled(false);
        return false;
      }
    }

    const response = await login({ ...loginForm });
    // console.log("response", response);
    if (response && "success" in response && response.success === 1) {
      // console.log("user logged in", loginForm);
      showToast("success", "Logged in Successfully!");
      navigate("/account");
    } else {
      setButtonsDisabled(false);
      // console.log("login failed!", response);
      showToast("error", response.error[0]);
    }
  };
  return (
    <>
      <Header />
      {log === 0 ? (
        <div>
          <div className="section mh-70">
            <div className="row">
              <div className="col-12">
                <div className="login-card text-center login-box">
                  <h1>Login</h1>
                  <p className="text-description mb-0">
                    Login to access your previous orders.
                  </p>
                  <input
                    onChange={(e) => handleChange(e)}
                    name="email"
                    type="email"
                    placeholder="Email address"
                    className="form-control "
                  />
                  <input
                    onChange={(e) => handleChange(e)}
                    name="password"
                    type="password"
                    placeholder="Password"
                    className="form-control "
                  />
                  <div>
                    <button
                      onClick={() => loginUser()}
                      disabled={buttonsDisabled}
                      className="btn btn-primary btn-gold w-100 mt-20 mb-10"
                    >
                      {buttonsDisabled ? "Loading..." : "Login"}
                    </button>
                  </div>
                  <br />
                  New to our website?{" "}
                  <Link to="../register" className="text-description">
                    Register Now
                  </Link>
                  <br />
                  <Link to="../forget" className="text-description">
                    Forgot Password
                  </Link>
                </div>
              </div>
            </div>
            <br />
            <br />
          </div>
        </div>
      ) : (
        <Loading />
      )}
      <FooterDesktop page="/profile" />
    </>
  );
};

export default Login;
