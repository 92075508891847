import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { CatalogContext } from "../../contexts";

const HomeDecor = () => {
  const { homeAndDecor } = useContext(CatalogContext);
  
  return (
    <section className="most-wanted dark-section pt-50 off-bg">
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <h3>Explore The Collection</h3>
          <p className="text-description">
            Wide range of silver artifacts to choose from.
          </p>
        </div>
      </div>
      <div className="row">
        {homeAndDecor.map((category,index) => 
        <div className="test_box box-01 col-4" key={index}>
        <div
          className="inner"
          style={{
            backgroundImage:
              `url("${category.image}")`
          }}
        />
        <div className="text-center">
          <h1 className="test_title">{category.name}</h1>
          <Link className="test_link" to={`/categories/${category.category_id}`}>
            Shop Now
          </Link>
        </div>
      </div>
        )}
       
      </div>
      <br />
    </div>
  </section>
  
  );
};

export default HomeDecor;
