import React from "react";
import { Link } from "react-router-dom";

const OrderCard = ({ order }) => {
  return (
    <div className="orderCard">
      <div className="row">
        <div className="col-6">
          <p className="font-18 font-600 text-gold mb-0">
            ORDER-{order.order_id}
          </p>
        </div>
        <div className="col-6 ">
          <p className="font-14 text-description fr mb-0">
            <i className="bi bi-calendar"></i> &nbsp;
            {order.date_added.substr(order.date_added.indexOf(",") + 1, 13)}
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <p className="font-16 text-description mt-10 mb-0">
            <i className="bi bi-person-circle"></i>&nbsp; {order.name}
          </p>
          <p className="font-16 text-description mt-10 mb-0">
            <i className="bi bi-geo-alt"></i>&nbsp;{" "}
            {order.address.shipping_address_1},&nbsp;{" "}
            {order.address.shipping_city}&nbsp; {order.address.shipping_zone}
            &nbsp; {order.address.shipping_country}&nbsp;{" "}
            {order.address.shipping_postcode}
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12 ">
          <p className="text-description mb-0 mt-10">
            <b>Order Total:</b>
            &nbsp; &nbsp;
            <font className="font-18 font-600 text-gold">{order.total}</font>
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-center">
          <hr />
          <Link to={`/OrderInfo/${order.order_id}`}>
            <button className="btn btn-primary btn-gold">
              {" "}
              <i className="bi bi-info-circle-fill font-16"></i> &nbsp;Order
              Info
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OrderCard;
