import React from 'react'
import { Link } from 'react-router-dom';
import shop from './shop.jpeg';


const Behind = () => {
  return (
   
        <div className="container our-story-section">
            <div className="row d-flex align-items-center">
              
            <div className="col-sm-6 col-12 text-center">
  <br />
  <img
    src={shop}
    alt=""
    className="img-fluid story-img"
  />
</div>

                <div className="col-12 col-sm-6 ourstory-text">
  <br />
  <h3 className="color-prime mt-10">Our Story</h3>
  <p>
    Code Silver, the very name holds itself two elements- the modernity of today
    and the authenticity of silver. Established in 2010, Code Silver is the
    unique blend of contemporary style, and handcrafted heritage, with detailing
    or baariki that is reflected in every piece, created by hand.
    <br />
    <br />
    Exquisite silver and silver plated work, transcending form. Who said Silver
    was reduced to jewelry and dining ware?
  </p>
  <Link to="/story">
    <button className="btn btn-primary">
      Learn More &nbsp;&nbsp;
      <i className="bi bi-arrow-right-circle-fill" />
    </button>
  </Link>
</div>
            </div>
        </div>
  
  )
}

export default Behind