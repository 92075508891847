import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { CatalogContext } from "../../contexts";


const HomeDecorDemo = () => {
  const { homeAndDecor } = useContext(CatalogContext);
  
  return (
    <section style={{background: `linear-gradient(90deg, rgba(42,40,53,1) 0%, rgba(36,61,72,1) 35%, rgba(150,156,170,1) 100%)`}} className="most-wanted dark-section pt-50 off-bg">
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <h3 className="demo-heading">Explore The Collection</h3>
          <p className="text-description text-white">
            Wide range of silver artifacts to choose from.
          </p>
        </div>
      </div>
      <div className="row">
        {homeAndDecor.map((category,index) => 
        <div className="test_box box-01 col-4" key={index}>
       
        <div
          className="inner"
          style={{
            backgroundImage:
              `url("${category.image}")`
          }}
        />
        <div className="text-center">
          <h1 className="test_title_demo test_title">{category.name}</h1>
        </div>
      </div>
        )}
       
      </div>
      <br />
    </div>
  </section>
  
  );
};

export default HomeDecorDemo;
