import React from "react";
import Header from "../common/Header";
import FooterDesktop from "../common/FooterDesktop";

const Shipping = () => {
  return (
    <div>
      <Header />
      <section className="mh-70">
        <div className="container mb-30">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="mt-100">Shipping & Delivery Policy</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <p className="font-14 text-description mt-30">
                <b>Order processing and shipping time</b><br />
Product should be delivered to customer by safe &amp; secure - customer should
intimate about receipt to persons at the time of order so no third person can
accept the order (identification proof being produced at the time of delivery).
Your order will be processed and shipped within 5 to 7 working days depending
upon the ordered items and after shipping delivery between 3 to 5 working days.
For remote areas and union territory it can take more than standard delivery
time. Codesilver will not be responsible for late deliveries. All orders within the
India are delivered using Courier Company tie upped with codesilver.<br /><br />
<b>Shipping Costs</b><br />
Shipping cost for every item has been mentioned with each product depending
upon the size/weight/packaging standard. Customers do not have to pay any
other charges to the courier company except the local taxes if any applicable
other than the GST.<br /><br />
<b>International shipping process (if applicable)</b><br />
Shipping cost for every item has been mentioned with each product depending
upon the size/weight/packaging standard. Customers may have to pay/ bear the
cost for other charges which may introduces by their local government other
than the courier charges to the courier company or the local government which
may be applicable as per the law of that Country.
              </p>
              <hr />
              <p className="font-14 text-descriptoin mt-30">
                <b>Delivery</b><br />
              We will deliver the goods ordered by you to the address you give us for delivery at
the time you make your order. It is your responsibility to ensure that the delivery
address you give us is correct and accurate and that there is somebody to sign for
and take delivery of the goods from the delivery service used.<br /><br />
All orders within the India are delivered using Courier Company tie upped with
codesilver.<br /><br />
You will become the owner of the goods you have ordered when they have been
dispatched to you. Once goods have been delivered to you they will be held at your
own risk and we will not be liable for their loss or destruction.<br /><br />
We make no promise that materials on our website are appropriate or available for
use in locations outside the India, and accessing the website from territories where
its contents are illegal or unlawful is prohibited. If you choose to access this site
from locations outside the India, you do so on your own initiative and are
responsible for compliance with local laws.<br /><br />
If your order is returned to us because the courier or postal service could not
complete the delivery to you for whatever reason, then you will be responsible for
the repeat delivery costs.
              </p>
            </div>
          </div>

        </div>
      </section>
      <FooterDesktop />
    </div>
  );
};

export default Shipping;
