import React, { useContext } from "react";
import { Link } from "react-router-dom";
import bg from './bg.jpg';

import { CatalogContext } from "../../contexts";

const Bareware = () => {
  const { barWare } = useContext(CatalogContext);
 
  return (
    <section
    className="most-wanted dark-section"
    style={{
      backgroundImage: `url("${bg}")`
    }}
  >
    <div className="container ">
      <div className="row">
        <div className="col-12 text-center">
          <br /><br />
          <h2>Top Trending Products</h2>
          <p>Selection of top trending products at Code Silver.</p>
        </div>
      </div>
      <br />
      <div className="row">
        {barWare.map((top,index)=>
        <div className="col-3">
        <Link to={`product/${top.product_id}`}>
         <img
          src={top.image}
          className="img-fluid"
          alt=""
          style={{ width: "100%" }}
        />
        <div className="product-card-body text-center">
          <h6
            className="mb-0 font-14"
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              marginTop: 15
            }}
          >
            {top.name}
          </h6>
          <p className="mb-0">
            <b> {top.price_formated}</b>
          </p>
        </div>
        </Link>
      </div>
     
        )}
        
       
      </div>
      <div className="col-12 text-center">
        <br />
        <a href="/categories/101">
          <button className="btn btn-primary">
            View All Products &nbsp;&nbsp;
            <i className="bi bi-arrow-right-circle-fill" />
          </button>
        </a>
        <br />
        <br />
        <br />
      </div>
      <br />
      <br />
    </div>
  </section>
  
  );
};

export default Bareware;
