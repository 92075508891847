import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../common/Header";
import FooterDesktop from "../../common/FooterDesktop";
import { CartContext } from "../../../contexts";

const ThankYou = () => {
  const { emptyCart } = useContext(CartContext);
  useEffect(() => {
    emptyCart();
  }, []);

  return (
    <div>
      <Header />

      <section className="mh-70">
        <div className="container mt-100">
          <div className="row text-center ">
            <h1 className="mtb-20 font-26">Thank you!</h1>
            <p className="font-20">Your order has been placed successfully!</p>
            <Link to="/" className="btn btn-primary btn-gold ">
              Continue Shopping
            </Link>
          </div>
        </div>
      </section>
      <FooterDesktop />
    </div>
  );
};

export default ThankYou;
