import HeaderWhite from "./Header";
import BigFooter from "./BigFooter";
import { Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import axios from "axios";

const Tracking = () => {
  // const inputRef = useRef();
  const [inputValue, setInputValue] = useState("");
  const id = inputValue;
  const check = `https://api.bluedart.com/servlet/RoutingServlet?handler=tnt&action=custawbquery&loginid=JA334456&awb=awb&numbers=${id}&format=html&lickey=5ab012e9bd464433eeab758459a9a0db&verno=1.3&scan=1`;

 

  return (
    <>
      <HeaderWhite />
      <div className="container" style={{ marginTop: "200px" }}>
        <div className="row justify-content-center" style={{ margin: "300px" }}>
          <h4 className="d-flex justify-content-center font-19 golden-color mb-0">
            <b>Track Your Order</b>
          </h4>
          <div
            className="col-12 d-flex justify-content-center "
            style={{ marginBottom: "150px", marginTop: "25px" }}
          >
            <input
              onChange={(e) => {
                setInputValue(e.target.value);
                console.log(e.target.value);
              }}
              value={inputValue}
              type="text"
              name=""
              id=""
              placeholder="Enter your Tracking ID"
              className="tracking-box"
              
            />
            
            <a href={`${check}`}>
              <button
                className="subscribe-btn"
              >
                Track
              </button>
            </a>
          </div>
        </div>
      </div>
      <BigFooter />
    </>
  );
};

export default Tracking;
