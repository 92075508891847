import React from "react";
import ProductCard from "./ProductCard";
import Header from "../../common/Header";
import FooterDesktop from "../../common/FooterDesktop";
import { useEffect, useContext } from "react";
import http from "../../../services/httpService";
import { useParams } from "react-router-dom";
import { useState } from "react";
import Loading from "../../common/Loading";
import Curtain from "../../common/Curtain";
import { SessionContext } from "../../../contexts";

const Categories = () => {
  const { currency } = useContext(SessionContext);
  const [isLoaded, setLoaded] = useState(0);
  const [products, setProducts] = useState();
  const [categoryDetails, setCategoryDetails] = useState();
  const [priceRange, setPriceRange] = useState([0, 999999, "0"]);
  const params = useParams();
  useEffect(() => {
    setLoaded(0);
    setTimeout(function () {
      setLoaded(1);
    }, 2000);
    setProducts(undefined);
    setCategoryDetails(undefined);
    setPriceRange([0, 999999, "0"]);
    const fetchData = async () => {
      try {
        const { data: fetchedProducts } = await http.get(
          http.apiEndpoint +
            "feed/rest_api/products&store_id=0&category=" +
            params.id,
          { headers: { ...http.headers, "X-Oc-Currency": currency } }
        );
        // console.log("fetchedProducts", fetchedProducts);
        if (
          typeof fetchedProducts == "object" &&
          "success" in fetchedProducts &&
          fetchedProducts.success === 1
        ) {
          setProducts(fetchedProducts.data);
        }

        const { data: fetchedCategoryDetails } = await http.get(
          http.apiEndpoint +
            "feed/rest_api/categories&store_id=0&id=" +
            params.id,
          { headers: { ...http.headers, "X-Oc-Currency": currency } }
        );

        if (
          typeof fetchedCategoryDetails == "object" &&
          "success" in fetchedCategoryDetails &&
          fetchedCategoryDetails.success === 1
        ) {
          setCategoryDetails(fetchedCategoryDetails.data);
        }
      } catch (error) {
        // console.log(error);
      }
    };
    fetchData();
  }, [params.id, currency]);
  const handlePriceFilterChange = (e) => {
    // console.log(e.target[e.target.selectedIndex].getAttribute("data-low"));
    const priceLow = parseInt(
      e.target[e.target.selectedIndex].getAttribute("data-low")
    );
    const priceHigh = parseInt(
      e.target[e.target.selectedIndex].getAttribute("data-high")
    );
    const optionNumber = e.target.value;
    setPriceRange([priceLow, priceHigh, optionNumber]);
  };
  return (
    <div>
      <Header />
      {!isLoaded && <Curtain />}
      <section className="section dark-bg ptb-20 mh-70">
        <div className="container text-center">
          <div className="row">
            <div className="col-12 ">
              <div className="mt-100" style={{ position: "relative" }}>
                <h1>{categoryDetails ? categoryDetails.name : ""}</h1>
              </div>
              <div className="col-12">
               {currency === "INR" ? (
                    <select
                      value={priceRange[2]}
                      className="filter-price mt--10"
                      onChange={(e) => {
                        handlePriceFilterChange(e);
                      }}
                    >
                      <option value="0" data-low="0" data-high="999999">
                        All Prices
                      </option>
                      <option value="1" data-low="0" data-high="5000">
                        ₹0 - ₹5,000
                      </option>
                      <option value="2" data-low="5000" data-high="15000">
                        ₹5000 - ₹15,000
                      </option>
                      <option value="3" data-low="15000" data-high="25000">
                        ₹15,000 - ₹25,000
                      </option>
                      <option value="4" data-low="25000" data-high="999999">
                        ₹25,000 and above
                      </option>
                    </select>
                  ) : (
                    <select
                      value={priceRange[2]}
                      onChange={(e) => {
                        handlePriceFilterChange(e);
                      }}
                    >
                      <option value="0" data-low="0" data-high="999999">
                        All Prices
                      </option>
                      <option value="1" data-low="0" data-high="100">
                        $0 - $100
                      </option>
                      <option value="2" data-low="100" data-high="300">
                        $100 - $300
                      </option>
                      <option value="3" data-low="300" data-high="500">
                        $300 - $500
                      </option>
                      <option value="4" data-low="500" data-high="999999">
                        $500 and above
                      </option>
                    </select>
                  )}
              </div>
            </div>
          </div>

          <div className="row">
            {products ? (
              products.length > 0 ? (
                products.filter((product) => {
                  return (
                    product.price >= priceRange[0] &&
                    product.price <= priceRange[1]
                  );
                }).length > 0 ? (
                  products
                    .filter((product) => {
                      return (
                        product.price >= priceRange[0] &&
                        product.price <= priceRange[1]
                      );
                    })
                    .sort((a, b) => a.price - b.price)
                    .map((product) => {
                      return (
                        <ProductCard
                          key={product.product_id}
                          product={product}
                        />
                      );
                    })
                ) : (
                  <div style={{ margin: "50px 0" }}>
                    <h6 className="text-center">
                      No products avilable in selected price range!
                    </h6>
                  </div>
                )
              ) : (
                <h6 className="text-center">coming soon!</h6>
              )
            ) : (
              <Loading />
            )}
          </div>
        </div>
      </section>
      <FooterDesktop />
    </div>
  );
};

export default Categories;
