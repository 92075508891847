import { createContext, useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import httpService from "../services/httpService";
import { SessionContext } from "./SessionContext";
import { CatalogContext } from "./CatalogContext";

const CartContext = createContext();
const CartProvider = (props) => {
  const { sessionId, currency} = useContext(SessionContext);
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem("cart")));
  const [checkout, setcheckout] = useState([]);
const { showToast } = useContext(CatalogContext);

  useEffect(() => {
    if (!cart) {
      localStorage.setItem("cart", "[]");
      setCart([]);
    }
    // console.log(cart);
  }, []);

  const updateCart = (newCart) => {
    localStorage.setItem("cart", JSON.stringify(newCart));
    setCart(newCart);
  };

  const addToCart = (product) => {
    let message = "Item added to cart!";
    let alreadyExist = false;
    const newCart = cart.map((cartItem, index) => {
      if (cartItem.product_id == product.product_id) {
        if (
          cartItem.option_details.length == 0 &&
          product.option_details.length == 0
        ) {
          const updatedItem = { ...cartItem };
          updatedItem.quantity =
            Number(cartItem.quantity) + Number(product.quantity);
          updatedItem.total = cartItem.total + (currency === "USD"?product.total*(1/0.014):product.total);
          updatedItem.total_excluding_tax =
            cartItem.total_excluding_tax + (currency === "USD"?product.total_excluding_tax*(1/0.014):product.total_excluding_tax);
          message = "Item updated in cart!";
          alreadyExist = true;
          return updatedItem;
        } else {
          if (
            _(cartItem.option_details)
              .differenceWith(product.option_details, _.isEqual)
              .isEmpty()
          ) {
            const updatedItem = { ...cartItem };
            updatedItem.quantity =
              Number(cartItem.quantity) + Number(product.quantity);
            updatedItem.total = cartItem.total + (currency === "USD"?product.total*(1/0.014):product.total);
            updatedItem.total_excluding_tax =
              cartItem.total_excluding_tax + (currency === "USD"?product.total_excluding_tax*(1/0.014):product.total_excluding_tax);
            message = "Item updated in cart!";
            alreadyExist = true;
            return updatedItem;
          }
        }
      }
      return cartItem;
    });
    if (!alreadyExist) {
      if(currency === "USD"){
        product.price = product.price*(1/0.014);
        product.price_excluding_tax = product.price_excluding_tax*(1/0.014);
        product.total = product.total*(1/0.014);
        product.total_excluding_tax = product.total_excluding_tax*(1/0.014);
      }
      newCart.push(product);
    }
    updateCart(newCart);
    showToast("success", message);


  };

  const deleteFromCart = (product) => {
    const newCart = cart.filter((cartItem, index) => {
      if (cartItem === product) {
        return false;
      } else {
        return true;
      }
    });
    updateCart(newCart);
    showToast("success", "Item removed from cart!");


  };

  const updateQuantity = (product, action) => {
    const newCart = [...cart];
    if (action === "increment") {
      newCart[cart.indexOf(product)].quantity =
        Number(newCart[cart.indexOf(product)].quantity) +
        Number(product.minimum);
    } else {
      if (product.quantity > product.minimum) {
        newCart[cart.indexOf(product)].quantity -= Number(product.minimum);
      }
    }
      newCart[cart.indexOf(product)].total =
      newCart[cart.indexOf(product)].quantity *
      newCart[cart.indexOf(product)].price;
      newCart[cart.indexOf(product)].total_excluding_tax =
      newCart[cart.indexOf(product)].quantity *
      newCart[cart.indexOf(product)].price_excluding_tax;
    updateCart(newCart);
  };

  const emptyCart = () => {
    setCart([]);
    localStorage.setItem("cart", "[]");
  };

  const validateCheckout = async () => {
    try {
      let deleteCart = await httpService.delete(
        httpService.apiEndpoint + "rest/cart/emptycart&store_id=0",
        { headers: { ...httpService.headers, "X-Oc-Session": sessionId, "X-Oc-Currency": currency} }
      );
      deleteCart = deleteCart.data;
      if (deleteCart && "success" in deleteCart && deleteCart.success === 1) {
        const allItems = cart.map((item, index) => {
          return {
            product_id: item.product_id,
            quantity: item.quantity,
            option: item.option,
          };
        });
        let addCart = await httpService.post(
          httpService.apiEndpoint + "rest/cart/bulkcart&store_id=0",
          allItems,
          { headers: { ...httpService.headers, "X-Oc-Session": sessionId, "X-Oc-Currency": currency } }
        );
        addCart = addCart.data;
        if (addCart && "success" in addCart && addCart.success === 1) {
          const cart = await httpService.get(
            httpService.apiEndpoint + "rest/cart/cart&store_id=0",
            { headers: { ...httpService.headers, "X-Oc-Session": sessionId, "X-Oc-Currency": currency} }
          );
          // console.log(cart.data);
          return cart.data;
        }
      }
    } catch (error) {
      // console.log("cart context validate checout error", error);
      return Promise.reject(error);
    }
  };
  const formatPrice = (val)=>{
    if(currency === 'USD'){
      val = (val/(1/0.014));
    }
    return parseFloat(val);
  }

  const addCoupon = async (code) =>{
    try {
   
    const coupon = await httpService.post(
      httpService.apiEndpoint + "rest/cart/coupon",{"coupon":code},
      { headers: { ...httpService.headers, "X-Oc-Session": sessionId, "X-Oc-Currency": currency} }
    );
    // console.log(coupon.data);
   if(coupon){
    showToast("success", "Coupon Applied!");
   }else{
    showToast("error", "Invalid Coupon!");

   }
    return coupon.data;
  }catch(error){
    showToast("error", "Invalid Coupon!");
    return Promise.reject(error);

  }
  }

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        deleteFromCart,
        updateQuantity,
        emptyCart,
        addCoupon,
        validateCheckout,
        formatPrice
      }}
    >
      {props.children}
    </CartContext.Provider>
  );
};

export { CartContext,CartProvider };
