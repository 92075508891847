import React from "react";
import { Link } from "react-router-dom";
import httpService from "../../../services/httpService";

const ProductCard = ({ product }) => {
  // console.log(product);
  return (
    <>
      {product ? (
        <div className="col-sm-4 col-6">
          <Link to={`/product/${product.product_id}`}>
            <div className="card card-styled card-styled-dark text-center mt-20 product-card">
              <img
                src={product.original_image || httpService.url + product.thumb}
                alt=""
                className="img-fluid p-cate-img"
              />
              <p className="font-14 mb-0 mt-10 text-gold">
                <b>{product.name}</b>
              </p>
              <p className="mb-0">
                {product.price_formated.slice(0, 1) +
                  product.price
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </p>
            </div>
          </Link>
        </div>
      ) : null}
    </>
  );
};

export default ProductCard;
