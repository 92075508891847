import React from "react";
import Header from "../../common/Header";
import FooterDesktop from "../../common/FooterDesktop";
import CategoryCard from "./CategoryCard";
import { CatalogContext } from "../../../contexts";
import { useContext } from "react";
import Loading from "../../common/Loading";
import { Link } from "react-router-dom";
const AllCategories = () => {
  const { homeAndDecor } = useContext(CatalogContext);
  return (
    <div>
      <Header />
      <section className="section dark-bg ptb-20">
        <div className="container text-center mh-70">
          <div className="row">
            <div className="col-12 ">
              <h1 className="mt-100">Shop</h1>
            </div>
          </div>
          <div className="row">
        {homeAndDecor.map((category,index) => 
        <Link className="test_link" to={`/categories/${category.category_id}`}>
       <div className="test_box box-01 col-12" key={index}>
        <div
          className="inner"
          style={{
            backgroundImage:
              `url("${category.image}")`
          }}
        />
        <div className="text-center">
          <h1 className="test_title">{category.name}</h1>
         
            Shop Now
          
        </div>
       
      </div>
       </Link>
        )}
       
      </div>
        </div>
      </section>
      <FooterDesktop page="/AllCategories" />
    </div>
  );
};

export default AllCategories;
