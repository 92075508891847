import React, { useState, useEffect } from "react";
import Header from "../common/Header";
import SliderTop from "./slider/SliderTop";
import SliderTopMobile from "./slider/SliderTopMobile";

import Bareware from "./Bareware";
import BarewareMobile from "./BarewareMobile";
import Behind from "./behind/Behind";

import HomeDecorMobile from "./HomeDecorMobile";
import HomeDecor from "./HomeDecor";

import FooterDesktop from "../common/FooterDesktop";
import Curtain from "../common/Curtain";

const Home = () => {
  const [isLoaded, setLoaded] = useState(0);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 650);
  const updateMedia = () => {
    setDesktop(window.innerWidth > 765);
  };
  useEffect(() => {
    setTimeout(function () {
      setLoaded(1);
    }, 2000);
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  return (
    <div>
      <Header />
      {!isLoaded && <Curtain />}
      {isDesktop ? <SliderTop /> : <SliderTopMobile />}
     
      {isDesktop ? <HomeDecor /> : <HomeDecorMobile />}
      
      {isDesktop ? <Bareware /> : <BarewareMobile />}

      <Behind />
      
     <br />
      <FooterDesktop page="/" />
    </div>
  );
};

export default Home;
