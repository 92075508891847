import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { CatalogContext } from "../../contexts";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  mobile: {
    breakpoint: { max: 1950, min: 0 },
    items: 1.3,
  },
};

const HomeDecorMobile = () => {
  const { homeAndDecor } = useContext(CatalogContext);
  return (
    <>
      <div className="row">
        <div className="col-12 text-center">
          <br />
          <h3>Explore The Collection</h3>
          <p className="text-description">
            Wide range of silver artifacts to choose from.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
        <Carousel
                responsive={responsive}
                showDots={false}
                arrows={true}
                className="trending-slider p-0"
              >
          {homeAndDecor.map((category, index) => (
            
              <div className="test_box_mob box-01">
                <div
                  className="inner"
                  style={{
                    backgroundImage: `url("${category.image}")`,
                  }}
                >
                 
                    <div className="flex_this text-center"><br/><br/><br/><br/>
                      <h1 className="test_title_mob">{category.name}</h1>

                      <Link
                        to={`/categories/${category.category_id}`}
                        className="test_link_mob"
                      >
                        Shop Now
                      </Link>
                    </div>
                  
                </div>
              </div>
           
          ))}
           </Carousel>
        </div>
      </div>
      <br />
    </>
  );
};

export default HomeDecorMobile;
