import { createContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import httpService from "../services/httpService";

const SessionContext = createContext();

const SessionProvider = (props) => {
  const [sessionId, setSessionId] = useState();
  const [latest, setLatest] = useState(0);
  const[currency, setCurrency] = useState("INR");

  useEffect(() => {
    localStorage.removeItem("session");
    localStorage.removeItem("update_link");
    const fetchData = async () => {
      try {
        const { data: sessionData } = await httpService.get(
          httpService.apiEndpoint + "feed/rest_api/session&store_id=0",
          { headers: httpService.headers }
        );

        if (typeof sessionData === "object") {
          if (
            sessionData &&
            "success" in sessionData &&
            sessionData.success === 1 &&
            "error" in sessionData &&
            sessionData.error.length === 0
          ) {
            setSessionId(sessionData.data.session);
            localStorage.setItem("session", sessionData.data.session);
          } else {
            // console.log(sessionData.error[0]);
          }
        } else {
          // console.log(sessionData);
          // console.log("Unable to connect at the moment!");
        }

        const { data: version } = await httpService.get(
          httpService.apiEndpoint + "rest/login/currentVersion"
        );

        if (typeof version === "object") {
          if (
            version &&
            "success" in version &&
            version.success === 1 &&
            "error" in sessionData &&
            sessionData.error.length === 0
          ) {
            if (version.data.version === "1.0") {
              setLatest(1);
              // console.log("version ok!");
            } else {
              localStorage.setItem("update_link", version.data.link);
              setLatest(-1);
            }
          } else {
            setLatest(1);
            localStorage.removeItem("update_link");
          }
        } else {
          setLatest(1);
          localStorage.removeItem("update_link");
        }

      try{
          const { data: currency } = await httpService.get(
            httpService.apiEndpoint + "rest/login/currentCurrency"
          );
  
          if (typeof currency === "object") {
            if (
              currency &&
              "success" in currency &&
              currency.success === 1
            ) {
              setCurrency(currency.data);
            } 
          } 
        }catch(error){
          // console.log('cant fetch currency');
        }

        
      } catch (error) {
        // console.log("error starting session", error);
        setLatest(1);
        localStorage.removeItem("update_link");
      }
    };
    fetchData();
  }, []);

  return (
    <SessionContext.Provider value={{ sessionId, latest, currency, setCurrency }}>
      {props.children}
    </SessionContext.Provider>
  );
};

export { SessionContext, SessionProvider };
