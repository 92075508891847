import React from "react";
import { useContext } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import bg from './bg.jpg';
import { CatalogContext } from "../../contexts";

const responsive = {
  mobile: {
    breakpoint: { max: 1950, min: 0 },
    items: 1.3,
  },
};

const BarewareMobile = () => {
  const { barWare } = useContext(CatalogContext);
  return (
    <section
    className="most-wanted dark-section"
    style={{
      backgroundImage: `url("${bg}")`
    }}
  >
      <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <br />
          <h2>Top Trending Products</h2>
          <p>Selection of top trending products at Code Silver.</p>
        </div>
      </div>

        <div className="row">
        
            
              <Carousel
                responsive={responsive}
                showDots={false}
                arrows={true}
                className="trending-slider p-0"
              >
             {barWare.map((top,index)=>
        <Link to={`product/${top.product_id}`}>
        <div className="col-12" style={{"padding":"10px"}}>
        <img
          src={top.image}
          className="img-fluid"
          alt=""
          style={{ width: "100%" }}
        />
        <div className="product-card-body text-center">
          <h6
            className="mb-0 font-14"
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              marginTop: 15
            }}
          >
            {top.name}
          </h6>
          <p className="mb-0">
            <b> {top.price_formated}</b>
          </p>
        </div>
        </div>
        </Link>
          )}
              </Carousel>
            
          
        </div>

        <div className="row">
          <div className="col-6 offset-3 mt-20 text-center">
            <button className="btn btn-primary btn-gold w-100 mb-30">
              SHOP NOW
            </button>
            <br /> <br />
          </div>
        </div>
      </div>
    </section>
  );
};

export default BarewareMobile;
