import React, { useState, useEffect, useContext } from "react";
import Header from "../../common/Header";
import FooterDesktop from "../../common/FooterDesktop";
import { Link, useNavigate } from "react-router-dom";
import OrderCard from "./OrderCard";
import { CatalogContext, SessionContext, UserContext } from "../../../contexts";
import httpService from "../../../services/httpService";
import Loading from "../../common/Loading";

const PendingOrders = () => {
  const navigate = useNavigate();

  const [orders, setOrders] = useState();

  const { sessionId } = useContext(SessionContext);

  const { orderStatuses } = useContext(CatalogContext);

  const { log } = useContext(UserContext);

  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  const { showToast } = useContext(CatalogContext);

  useEffect(() => {
    const getAllOrders = async () => {
      try {
        const {
          data: { data: allOrders },
        } = await httpService.get(
          httpService.apiEndpoint + "rest/order/orders&store_id=0",
          { headers: { ...httpService.headers, "X-Oc-Session": sessionId } }
        );
        // console.log(allOrders);
        setOrders(allOrders);
      } catch (error) {
        // console.log(error);
        if (error.response) {
          showToast("error", error.response.data.error[0]);
        } else {
          showToast("error", "Unable to fetch your orders!");
        }
      }
    };
    if (sessionId && log === 1) {
      getAllOrders();
    } else if (log === 0) {
      navigate("/login");
    }
  }, [sessionId, log]);

  return (
    <>
      <Header />
      {log === 1 && orders ? (
        <div>
          <div className="section mh-70">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center">
                  <h1 className="mt-120">Orders History</h1>
                  <Link
                    to="../account"
                    className="back_btn text-description fl"
                  >
                    {" "}
                    <i className="bi bi-arrow-left-circle-fill"></i> Go Back
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  {orders &&
                  orders.filter(
                    (order) =>
                      Number(
                        orderStatuses[
                          order.status.replace(/[^A-Z0-9]/gi, "").toLowerCase()
                        ]
                      ) === 1
                  ).length !== 0 ? (
                    orders
                      .filter(
                        (order) =>
                          Number(
                            orderStatuses[
                              order.status
                                .replace(/[^A-Z0-9]/gi, "")
                                .toLowerCase()
                            ]
                          ) === 1
                      )
                      .map((order) => {
                        return <OrderCard key={order.order_id} order={order} />;
                      })
                  ) : (
                    <div className="container mt-100">
                      <div className="row text-center ">
                        <h1 className="mtb-20">You have no orders!</h1>
                        <Link to="/" className="btn btn-primary btn-gold ">
                          Continue Shopping
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>
      ) : (
        <Loading />
      )}
      <FooterDesktop page="/profile" />
    </>
  );
};

export default PendingOrders;
