import React from "react";
import { Link } from "react-router-dom";
import ProductCard from "./ProductCard";
import Header from "../../common/Header";
import FooterDesktop from "../../common/FooterDesktop";
import { useEffect, useContext } from "react";
import http from "../../../services/httpService";
import { useParams } from "react-router-dom";
import { useState } from "react";
import Loading from "../../common/Loading";
import Curtain from "../../common/Curtain";
import { SessionContext } from "../../../contexts";

const Search = () => {
  const { currency } = useContext(SessionContext);
  const [isLoaded, setLoaded] = useState(0);
  const [products, setProducts] = useState();
  const params = useParams();
  useEffect(() => {
    setLoaded(0);
    setTimeout(function () {
      setLoaded(1);
    }, 2000);
    setProducts(undefined);
    const fetchData = async () => {
      try {
        const { data: fetchedProducts } = await http.get(
          http.apiEndpoint +
            "feed/rest_api/products&store_id=0&search=" +
            params.search,
          { headers: { ...http.headers, "X-Oc-Currency": currency } }
        );
        // console.log("fetchedProducts", fetchedProducts);
        if (
          typeof fetchedProducts == "object" &&
          "success" in fetchedProducts &&
          fetchedProducts.success === 1
        ) {
          setProducts(fetchedProducts.data);
        }
      } catch (error) {
        // console.log(error);
      }
    };
    fetchData();
  }, [params.search]);
  return (
    <div>
      <Header />
      {!isLoaded && <Curtain />}
      <section className="section dark-bg ptb-20">
        <div className="container text-center">
          <div className="row">
            <div className="col-12 ">
              <h1 className="mt-100">
                {params.search && products && products.length !== 0
                  ? `Search results for ${params.search}`
                  : ""}
              </h1>
            </div>
          </div>

          <div className="row">
            {products ? (
              products.length > 0 ? (
                products.map((product) => {
                  return (
                    <ProductCard key={product.product_id} product={product} />
                  );
                })
              ) : (
                <>
                  <div className="mt-100 mb-30 mh-70">
                    <h1 className="text-center">
                      No products found matching with "{params.search}"
                    </h1>
                    <Link to="/" className="btn btn-primary btn-gold ">
                      Show All products
                    </Link>
                  </div>
                </>
              )
            ) : (
              <Loading />
            )}
          </div>
        </div>
      </section>
      <FooterDesktop />
    </div>
  );
};

export default Search;
