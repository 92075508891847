import React from "react";
import { Link } from "react-router-dom";

const AddressCard = ({ address, selectAddress, showCheck }) => {
  // console.log(showCheck);
  return (
    <div
      onClick={() => selectAddress(address)}
      className="col-sm-4 col-12"
      style={{ cursor: "pointer" }}
    >
      <div className="address-card card-shadow p-20">
        {showCheck ? (
          <i className="bi bi-check text-gold fr font-22"></i>
        ) : null}

        <p className="font-18 text-description mb-10">
          {address.address_1}
          <br />
          {address.address_2}
          <br />
          {address.city}, {address.zone}, {address.country} - {address.postcode}
        </p>
        {/* <Link to={`/editAddress/${address.address_id}`} className="text-gold">
          <i className="bi bi-pencil"></i> Edit Address
        </Link> */}
      </div>
    </div>
  );
};

export default AddressCard;
