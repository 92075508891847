import React from "react";
import { Link } from "react-router-dom";

export const DropDown = ({ classDrop }) => {
  return (
    <div id="drop_menu" className={classDrop}>
      <div className="row p-0">
        <div className="col-5 pl-10 text-left drop-down-1">
          <h6 className="font-14">Home &amp; Decor</h6>

          <Link to="/categories/76">Art House</Link>
          <Link to="/categories/103">Pichwai Photo Frames</Link>
          <Link to="/categories/88">Furniture</Link>
          <Link to="/categories/81"> photo frames</Link>
          <Link to="/categories/77">Tea-light holder</Link>

          <Link className="p-0 mt-10" to="/categories/72">
            <h6 className="font-14">Tableware</h6>
          </Link>
          <Link className="p-0" to="/categories/70">
            <h6 className="font-14">Travel In Style</h6>
          </Link>
          <Link className="p-0" to="/categories/83">
            <h6 className="font-14">Hardware Collection</h6>
          </Link>
        </div>
        <div className="col-5 pl-10 text-left drop-down-2">
          <Link to="/categories/86">
            <h6 className="font-14">Lamps</h6>
          </Link>
          <Link to="/categories/73">
            <h6 className="font-14">Barware</h6>
          </Link>
          <Link to="/categories/85">
            <h6 className="font-14">Mauve By Codesilver</h6>
          </Link>
          <Link to="/categories/71">
            <h6 className="font-14">Office Accessories</h6>
          </Link>
          <Link to="/categories/84">
            <h6 className="font-14">Rakhi</h6>
          </Link>
          <Link to="/categories/82">
            <h6 className="font-14">Religious Range</h6>
          </Link>
          <Link to="/categories/75">
            <h6 className="font-14">Something Different</h6>
          </Link>
          <Link to="/categories/89">
            <h6 className="font-14">Trophies &amp; Mementos</h6>
          </Link>
          <Link to="/categories/74">
            <h6 className="font-14">We love babies</h6>
          </Link>
          <Link to="/categories/102">
            <h6 className="font-14">Vases</h6>
          </Link>
         
        </div>
      </div>
    </div>
  );
};
