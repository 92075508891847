import React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import {  SessionContext } from "../../contexts";
function hideSidebar() {
  document.getElementById("sidebar-bg").style.display = "none";
  document.getElementById("side-bar").style.left = "-270px";
}

const SideBar = () => {
  const { currency, setCurrency } = useContext(SessionContext);
  return (
    <>
      <div id="sidebar-bg" onClick={() => hideSidebar()}></div>
      <div id="side-bar">
        <i
          onClick={() => hideSidebar()}
          className="bi bi-x font-22 text-gold"
          id="close-sidebar"
        ></i>
       <nav className="mobile_nav">
       <select
              value={currency}
              onChange={(e) => {
                setCurrency(e.target.value);
              }}
              className="currency_drop"
            >
              <option value="INR">INR</option>
              <option value="USD">USD</option>
            </select>
            <hr />
  <a className="golden-color" href="/categories/73">
    Barware
  </a>
  <hr />
  <a className="golden-color" href="/categories/83">
    Hardware Collection
  </a>
  <hr />
  <a className="golden-color" href="/">
    Home &amp; Decor
    <hr />
    <span />
  </a>
  <a className="golden-color" href="/categories/76">
    &nbsp;&nbsp; Art House
  </a>
  <hr />
  <a className="golden-color" href="/categories/103">
    &nbsp;&nbsp; Pichwai Photo Frames
  </a>
  <hr />
  <a className="golden-color" href="/categories/88">
    &nbsp;&nbsp; Furniture
  </a>
  <hr />
  <a className="golden-color" href="/categories/81">
    &nbsp;&nbsp; Photo Frames
  </a>
  <hr />
  <a className="golden-color" href="/categories/77">
    &nbsp;&nbsp; Tea-light holder
  </a>
  <hr />
  <a className="golden-color" href="/categories/85">
    Mauve By Code Silver
  </a>
  <hr />
  <a className="golden-color" href="/categories/71">
    Office Accessories
  </a>
  <hr />
  <a className="golden-color" href="/categories/84">
    Rakhi
  </a>
  <hr />
  <a className="golden-color" href="/categories/82">
    Religious Range
  </a>
  <hr />
  <a className="golden-color" href="/categories/75">
    Something Different
  </a>
  <hr />
  <a className="golden-color" href="/categories/72">
    Tableware
  </a>
  <hr />
  <a className="golden-color" href="/categories/86">
  Lamps
  </a>
  <hr />
  
  <a className="golden-color" href="/categories/70">
    Travel In Style
  </a>
  <hr />
  <a className="golden-color" href="/categories/89">
    Trophies &amp; Mementos
  </a>
  <hr />
  <a className="golden-color" href="/categories/74">
    We love babies
  </a>
  <hr />
  <a className="golden-color" href="/categories/102">
  Vases
  </a>
</nav>

           &nbsp;&nbsp;&nbsp; <select
              value={currency}
              onChange={(e) => {
                setCurrency(e.target.value);
              }}
              className="currency_drop "
            >
              <option value="INR">INR</option>
              <option value="USD">USD</option>
            </select>
      </div>
    </>
  );
};

export default SideBar;
