import React, { useState, useEffect, useContext } from "react";
import Header from "../common/Header";
import FooterDesktop from "../common/FooterDesktop";
import { Link, useNavigate } from "react-router-dom";
import { CatalogContext, UserContext } from "../../contexts";
import Loading from "../common/Loading";

const EditProfile = () => {
  const { userDetails, log, user, updateProfile } = useContext(UserContext);
  const [userForm, setUserForm] = useState();
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const { showToast } = useContext(CatalogContext);
  const navigate = useNavigate();
  const handleChange = (e) => {
    const form = { ...userForm };
    form[e.target.name] = e.target.value;
    setUserForm(form);
  };

  const updateUser = async () => {
    setButtonsDisabled(true);

    const keys = Object.keys(userForm);
    for (let i = 0; i < keys.length; i++) {
      if (userForm[keys[i]] === "") {
        showToast("error", `${keys[i]} is required!`);
        setButtonsDisabled(false);
        return false;
      }
    }

    const response = await updateProfile(userForm);
    // console.log("response", response);
    if (response && "success" in response && response.success === 1) {
      // console.log("user profile updated", userForm);
      showToast("success", "Profile updated successfully!");
      setButtonsDisabled(false);
    } else {
      setButtonsDisabled(false);
      // console.log("update failed!", response);
      showToast("error", response.error[0]);
    }
  };

  useEffect(() => {
    if (log === 1 && user && userDetails) {
      // console.log(userDetails);
      setUserForm({
        firstname: userDetails.firstname,
        lastname: userDetails.lastname,
        email: userDetails.email,
        telephone: userDetails.telephone,
      });
    }
    if (log == 0) {
      navigate("/login");
    }
  }, [log, user, userDetails]);

  return (
    <>
      <Header />
      {user && userDetails && userForm && log === 1 ? (
        <div>
          <div className="section mh-70">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center">
                  <h1 className="mt-120">Edit Profile</h1>
                  <Link
                    to="../account"
                    className="back_btn text-description fl"
                  >
                    {" "}
                    <i className="bi bi-arrow-left-circle-fill"></i> Go Back
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col-12 offset-0 col-sm-8 offset-sm-2">
                  <div className="row">
                    <div className="col-sm-6 offset-sm-3 col-12 offset-0">
                      <hr />
                      <fieldset id="account">
                        <div className="form-group" style={{ display: "none" }}>
                          <label className="control-label">
                            Customer Group
                          </label>
                          <div className="radio">
                            <label>
                              <input
                                type="radio"
                                name="customer_group_id"
                                defaultValue={1}
                                defaultChecked="checked"
                              />
                              Default
                            </label>
                          </div>
                        </div>
                        <div className="form-group required">
                          <input
                            type="text"
                            value={userForm.firstname}
                            name="firstname"
                            onChange={(e) => handleChange(e)}
                            placeholder="First Name"
                            id="input-payment-firstname"
                            className="form-control"
                          />
                        </div>
                        <div className="form-group required">
                          <input
                            type="text"
                            value={userForm.lastname}
                            name="lirstname"
                            onChange={(e) => handleChange(e)}
                            placeholder="Last Name"
                            id="input-payment-lastname"
                            className="form-control"
                          />
                        </div>
                        <div className="form-group required">
                          <input
                            type="email"
                            value={userForm.email}
                            name="email"
                            onChange={(e) => handleChange(e)}
                            placeholder="E-Mail"
                            id="input-payment-email"
                            className="form-control"
                          />
                        </div>
                        <div className="form-group required">
                          <input
                            type="number"
                            value={userForm.telephone}
                            name="telephone"
                            onChange={(e) => handleChange(e)}
                            placeholder="Mobile Number"
                            id="input-payment-telephone"
                            className="form-control"
                          />
                        </div>
                        <input
                          style={{ width: "69%", float: "right" }}
                          type="hidden"
                          name="telephone"
                          defaultValue=""
                          placeholder="Mobile Number"
                          id="input-payment-telephone1"
                        />
                        <div style={{ display: "none" }} className="form-group">
                          <label
                            className="control-label"
                            htmlFor="input-payment-fax"
                          >
                            Fax
                          </label>
                          <input
                            type="text"
                            name="fax"
                            defaultValue=""
                            placeholder=""
                            id="input-payment-fax"
                            className="form-control"
                          />
                        </div>
                      </fieldset>
                    </div>
                  </div>

                  <br />
                  <div className="buttons">
                    <div className="text-center">
                      <div>
                        <button
                          onClick={updateUser}
                          disabled={buttonsDisabled}
                          id="button-guest"
                          data-loading-text="Loading..."
                          className="btn btn-primary btn-gold w-100"
                        >
                          {buttonsDisabled ? "Updating" : "Continue"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loading />
      )}
      <FooterDesktop page="/profile" />
    </>
  );
};

export default EditProfile;
