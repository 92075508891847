import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { CartContext, SessionContext } from "../../../contexts";
const CartTotal = ({ cart, handleCheckout, buttonsDisabled, addCoupon }) => {
  const { formatPrice } = useContext(CartContext);
  const { currency } = useContext(SessionContext);
  const [code,setCode]= useState('');
  
  const handleCoupon=(e)=>{
     setCode(e.target.value)
  }

  return (
    <div className="row">
      <div className="col-sm-7">
            <h6>Apply Coupon</h6>
            <input 
            type="text" 
            placeholder='Coupon Code' 
            className="form-control coupon-input fl"
            onChange={(e)=>handleCoupon(e)}
            name="couponCode"
            value={code}
            />
       <button
        className="btn btn-primary fl"
        onClick={()=>addCoupon(code)}
        >Apply</button>
         </div>

      <div className="col-sm-5">
        <table style={{ width: "100%" }} className="table">
          <tbody>
            <tr style={{ border: "none" }}>
              <td
                style={{ border: "none", textTransform: "uppercase" }}
                className="text-center"
              >
                <strong className="text-description">Sub-Total:</strong>
              </td>
              <td
                style={{ border: "none", textTransform: "uppercase" }}
                className="text-right text-description"
              >
                {(currency === "USD" ? "$" : "₹") +
                  parseFloat(
                    _.sum(
                      cart.map((cartItem) =>
                        formatPrice(cartItem.total_excluding_tax)
                      )
                    )
                  )
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </td>
            </tr>
            <tr style={{ border: "none" }}>
              <td
                style={{ border: "none", textTransform: "uppercase" }}
                className="text-center"
              >
                <strong className="text-description">GST:</strong>
              </td>
              <td
                style={{ border: "none", textTransform: "uppercase" }}
                className="text-right text-description"
              >
                {(currency === "USD" ? "$" : "₹") +
                  parseFloat(
                    _.sum(cart.map((cartItem) => formatPrice(cartItem.total))) -
                      _.sum(
                        cart.map((cartItem) =>
                          formatPrice(cartItem.total_excluding_tax)
                        )
                      )
                  )
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </td>
            </tr>
            <tr style={{ border: "none" }}>
              <td
                style={{ border: "none", textTransform: "uppercase" }}
                className="text-center"
              >
                <strong className="text-gold">Total:</strong>
              </td>
              <td
                style={{ border: "none", textTransform: "uppercase" }}
                className="text-right text-gold"
              >
                <strong className="text-gold">
                  {" "}
                  {(currency === "USD" ? "$" : "₹") +
                    parseFloat(
                      _.sum(cart.map((cartItem) => formatPrice(cartItem.total)))
                    )
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </strong>
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <div className="row">
          <div className="col-sm-12">
            <div className="buttons clearfix">
              <div className="pull-right">
                <Link to="">
                  <button
                    style={{ color: "#fff" }}
                    href="index.php?route=account/quotation/add"
                    className="btn btn-primary btn-gold  btn-outline"
                  >
                    Login
                  </button>
                </Link>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <button
                  style={{ color: "#fff" }}
                  className="btn btn-primary btn-gold"
                  onClick={() => handleCheckout()}
                  disabled={buttonsDisabled}
                >
                  {buttonsDisabled ? "Please Wait..." : "Checkout"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartTotal;
