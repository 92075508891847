import userEvent from "@testing-library/user-event";
import React, { useState, useContext, useEffect } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import {
  UserContext,
  SessionContext,
  CartContext,
  CatalogContext,
} from "../../../contexts";
import _, { lowerCase, zip } from "lodash";
import { toast } from "react-toastify";
import httpService from "../../../services/httpService";
import Select from "react-select";
import Billing from "./Billing";
import Shipping from "./Shipping";
import Confirm from "./Confirm";
import AddressBook from "./AddressBook";
import Header from "../../common/Header";
import FooterDesktop from "../../common/FooterDesktop";
import Loading from "../../common/Loading";
const Checkout = () => {
  const [checkoutStep, setCheckoutStep] = useState("pending");
  const { showToast } = useContext(CatalogContext);
  const { user, log } = useContext(UserContext);
  const { cart, emptyCart, validateCheckout } = useContext(CartContext);
  const { sessionId, currency, setCurrency } = useContext(SessionContext);
  const [cc, setCc] = useState(91);

  const [addresses, setAddresses] = useState();
  const [checkout, setCheckout] = useState({});
  const [guestUser, setGuestUser] = useState({
    firstname: "",
    lastname: "",
    email: "",
    telephone: "",
    city: "",
    address_1: "",
    //address_2: "",
    country_id: "",
    postcode: "",
    zone_id: null,
  });
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [shipping, setShipping] = useState({
    firstname: "",
    lastname: "",
    city: "",
    address_1: "",
    address_2: "",
    country_id: "",
    postcode: "",
    zone_id: "",
    //company: "",
  });
  const [payment, setPayment] = useState({
    firstname: "",
    lastname: "",
    city: "",
    address_1: "",
    address_2: "",
    country_id: "",
    postcode: "",
    zone_id: "",
    company: "",
  });
  const [payUForm, setPayUForm] = useState({
    key: "",
    txnid: "",
    amount: "",
    firstname: "",
    email: "",
    phone: "",
    productinfo: "",
    surl: "",
    furl: "",
    hash: "",
    Pg: "",
  });
  const [payPalForm, setPayPalForm] = useState({
    action: "",
    business: "",
    currency_code: "",
    first_name: "",
    last_name: "",
    address1: "",
    address2: "",
    city: "",
    zip: "",
    country: "",
    email: "",
    lc: "",
    return: "",
    notify_url: "",
    cancel_return: "",
    custom: "",
    discount_amount_cart: "",
    products: [],
  });

  const [commentt, setComment] = useState("");

  const [existingPayment, setExistingPayment] = useState({ address_id: "" });
  const [existingShipping, setExistingShipping] = useState({ address_id: "" });

  const [selectedPaymentAddress, setSelectedPaymentAddress] = useState();
  const [countries, setCountries] = useState([]);
  const [zones, setZones] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      await handleCheckout();
      const {
        data: { data: countriesData },
      } = await httpService.get(httpService.apiEndpointShort + "countries", {
        headers: httpService.headers,
      });
      setCountries(
        countriesData.map((country, index) => {
          return { value: country.country_id, label: country.name };
        })
      );
      if (user) {
        try {
          const {
            data: { data: allAddresses },
          } = await httpService.get(
            httpService.apiEndpointShort + "account/address",
            { headers: { ...httpService.headers, "X-Oc-Session": sessionId } }
          );
          // console.log(allAddresses);
          setAddresses(allAddresses.addresses);
          if (allAddresses && allAddresses.addresses) {
            allAddresses.addresses.map((address) => {
              if (address.default === true) {
                setSelectedPaymentAddress(address.address_id);
                setPayment({
                  firstname: address.firstname,
                  lastname: address.lastname,
                  city: address.city,
                  address_1: address.address_1,
                  address_2: address.address_2,
                  country_id: address.country_id,
                  postcode: address.postcode,
                  zone_id: address.zone_id,
                  company: address.company,
                });

                setShipping({
                  firstname: address.firstname,
                  lastname: address.lastname,
                  city: address.city,
                  address_1: address.address_1,
                  address_2: address.address_2,
                  country_id: address.country_id,
                  postcode: address.postcode,
                  zone_id: address.zone_id,
                  company: address.company,
                });

                setExistingPayment({ address_id: address.address_id });
                setExistingShipping({ address_id: address.address_id });
              }
            });
          }
        } catch (error) {
          // console.log(error);
          if (error.response) {
            showToast("error", error.response.data.error[0]);
          } else {
            //showToast("error", "Unable to fetch your addresses!");
            navigate(-1);
          }
        }
        setCheckoutStep("paymentaddress");
      } else {
        setCheckoutStep("createguest");
      }
    };
    const handleCheckout = async () => {
      setButtonsDisabled(true);
      try {
        const checkout = await validateCheckout();
        // console.log(checkout);
        setButtonsDisabled(false);
        if (checkout && "error" in checkout && checkout.error.length !== 0) {
          showToast("error", checkout.error[0]);
          navigate(-1);
          return false;
        }
      } catch (error) {
        // console.log("cart page checkout error", error);
        setButtonsDisabled(false);
        if (error.response) {
          showToast("error", error.response.data.error[0]);
        } else {
          // showToast("error", "An error occured!");
          navigate(-1);
        }

        navigate(-1);
        return false;
      }
    };
    if (log === -1) {
      return;
    }
    if (!cart || cart.length === 0) {
      navigate(-1);
      return false;
    } else {
      fetchData();
    }
  }, [log]);

  const navigate = useNavigate();

  const handleCountryChange = async (e, obj, setObj) => {
    const form = { ...obj };
    form.country_id = e.value;
    if (e.value !== 99 && currency !== "USD") {
      setCurrency("USD");
    }
    form.zone_id = null;
    try {
      const {
        data: {
          data: { zone: countriesZones },
        },
      } = await httpService.get(
        httpService.apiEndpointShort + "countries/" + e.value,
        { headers: httpService.headers }
      );
      // console.log(countriesZones);
      setZones(
        countriesZones.map((zone, index) => {
          return { value: zone.zone_id, label: zone.name };
        })
      );
      setObj(form);
    } catch (error) {
      // console.log("zone select error", error);
    }
  };

  const handleZoneChange = async (e, obj, setObj) => {
    const form = { ...obj };
    // console.log(e);
    form.zone_id = e.value;
    setObj(form);
  };

  const handleChange = (e, obj, setObj) => {
    const form = { ...obj };

    form[e.target.name] = e.target.value;

    setObj(form);
  };

  const handlePaymentChange = (e, obj, setObj) => {
    const form = { ...obj };

    form.Pg = e;

    setPayUForm(form);
  };

  const handleCountryCodeChange = (e) => {
    setCc(e.target.value);
    // console.log(e.target.value);
  };

  const replicateAddress = async () => {
    const details = { ...guestUser };
    let shipAddress = {
      firstname: details.firstname,
      lastname: details.lastname,
      company: details.company,
      address_1: details.address_1,
      address_2: "",
      city: details.city,
      postcode: details.postcode,
      country_id: details.country_id,
      email: details.email,
      telephone: details.telephone,
    };

    shipAddress.zone_id = null;
    try {
      const {
        data: {
          data: { zone: countriesZones },
        },
      } = await httpService.get(
        httpService.apiEndpointShort + "countries/" + details.country_id,
        { headers: httpService.headers }
      );
      // console.log(countriesZones);
      setZones(
        countriesZones.map((zone, index) => {
          return { value: zone.zone_id, label: zone.name };
        })
      );
      shipAddress.zone_id = details.zone_id;
      setShipping(shipAddress);
    } catch (error) {
      // console.log("zone select error", error);
    }
  };

  const placeOrder = () => {
    const orderPlacer = async () => {
      try {
        let response = await httpService.put(
          httpService.apiEndpoint + "rest/confirm/confirm&store_id=0",
          {},
          {
            headers: {
              ...httpService.headers,
              "X-Oc-Session": sessionId,
              "X-Oc-Currency": currency,
            },
          }
        );
        response = response.data;
        // console.log("order place response-", response);
        if (
          response &&
          "success" in response &&
          response.success === 1 &&
          "error" in response &&
          response.error.length === 0
        ) {
          // console.log("order successfullycreated!");
          setButtonsDisabled(false);
          emptyCart();
          showToast("success", "Order placed successfully!");
          navigate("/thankyou");
        } else if (
          response &&
          "success" in response &&
          response.success !== 1 &&
          "error" in response &&
          response.error.length !== 0
        ) {
          // console.log(
          //   "order place unsucessful due to errors!",
          //   response.error[0]
          // );
          showToast("error", response.error[0]);
          setButtonsDisabled(false);
        } else {
          // console.log("order place unsucessful due to unknown!");
          // showToast("error", "Something went wrong!");
          navigate(-1);
          setButtonsDisabled(false);
        }
      } catch (error) {
        // console.log("error", error);
        setButtonsDisabled(false);
        const expectedError =
          error.response &&
          error.response.status >= 400 &&
          error.response.status < 500;

        if (expectedError) {
          showToast("error", error.response.data.error[0]);
          // console.log("order pace failed", error.response);
        } else {
          // showToast("error", "Something went wrong!");
          // console.log("order place failed", error);
          navigate(-1);
        }
      }
    };
    setButtonsDisabled(true);
    orderPlacer();
  };

  const createGuestUser = async () => {
    setButtonsDisabled(true);
    const keys = Object.keys(guestUser);
    let check = true;
    for (let i = 0; i < keys.length; i++) {
      if (guestUser[keys[i]] === "" || guestUser[keys[i]] === null) {
        showToast("error", `${keys[i]} is required!`);
        check = false;
        setButtonsDisabled(false);
        return false;
      }
    }
    if (check) {
      try {
        let gu = { ...guestUser };
        gu.telephone = cc + gu.telephone;
        let response = await httpService.post(
          httpService.apiEndpoint + "rest/guest/guest&store_id=0",
          gu,
          { headers: { ...httpService.headers, "X-Oc-Session": sessionId } }
        );
        response = response.data;
        // console.log("create user response-", response);
        if (
          response &&
          "success" in response &&
          response.success === 1 &&
          "error" in response &&
          response.error.length === 0
        ) {
          // console.log("guest user created");
          setCheckoutStep("guestshipping");
          setZones([]);
          setButtonsDisabled(false);
        } else if (
          response &&
          "success" in response &&
          response.success !== 1 &&
          "error" in response &&
          response.error.length !== 0
        ) {
          // console.log(
          //   "create user unsucessful due to errors!",
          //   response.error[0]
          // );
          showToast("error", response.error[0]);
          setButtonsDisabled(false);
        } else {
          // console.log("create user unsucessful due to unknown!");
          // showToast("error", "Something went wrong!");
          navigate(-1);
          setButtonsDisabled(false);
        }
      } catch (error) {
        // console.log("error", error);
        setButtonsDisabled(false);
        const expectedError =
          error.response &&
          error.response.status >= 400 &&
          error.response.status < 500;

        if (expectedError) {
          showToast("error", error.response.data.error[0]);
          // console.log("create user failed", error.response);
        } else {
          // showToast("error", "Something went wrong!");
          // console.log("create user failed", error);
          navigate(-1);
        }
      }
    }
  };

  const createShipping = async () => {
    setButtonsDisabled(true);
    const keys = Object.keys(shipping);
    let check = true;
    for (let i = 0; i < keys.length; i++) {
      if (shipping[keys[i]] === "" || shipping[keys[i]] === null) {
        if (keys[i] == "address_2") {
          continue;
        }
        showToast("error", `${keys[i]} is required!`);
        check = false;
        setButtonsDisabled(false);
        return false;
      }
    }
    if (check) {
      try {
        let sh = { ...shipping };
        sh.telephone = cc + sh.telephone;
        let response = await httpService.post(
          httpService.apiEndpoint +
            "rest/guest_shipping/guestshipping&store_id=0",
          sh,
          { headers: { ...httpService.headers, "X-Oc-Session": sessionId } }
        );
        response = response.data;
        // console.log("guest shipping response-", response);
        if (
          response &&
          "success" in response &&
          response.success === 1 &&
          "error" in response &&
          response.error.length === 0
        ) {
          // console.log("guest shipping created");

          let { data: shippingMethods } = await httpService.get(
            httpService.apiEndpoint +
              "rest/shipping_method/shippingmethods&store_id=0",
            { headers: { ...httpService.headers, "X-Oc-Session": sessionId } }
          );

          if (
            "error" in shippingMethods &&
            shippingMethods.error.length !== 0
          ) {
            throw new Error(400, shippingMethods.error[0]);
          }
          const shm = shippingMethods.data.shipping_methods[0].quote[0].code;
          let response = await httpService.post(
            httpService.apiEndpoint +
              "rest/shipping_method/shippingmethods&store_id=0",
            {
              shipping_method: shm,
              comment: "string1",
            },
            { headers: { ...httpService.headers, "X-Oc-Session": sessionId } }
          );
          response = response.data;
          if (
            response &&
            "success" in response &&
            response.success === 1 &&
            "error" in response &&
            response.error.length === 0
          ) {
            // console.log("shipping method created");
            let tt = await httpService.get(
              httpService.apiEndpoint + "feed/rest_api/productclasses",
              { headers: { ...httpService.headers, "X-Oc-Session": sessionId } }
            );

            let { data: paymentMethods } = await httpService.get(
              httpService.apiEndpoint +
                "rest/payment_method/payments&store_id=0",
              { headers: { ...httpService.headers, "X-Oc-Session": sessionId } }
            );

            if (
              "error" in paymentMethods &&
              paymentMethods.error.length !== 0
            ) {
              throw new Error(400, paymentMethods.error[0]);
            }

            let response = await httpService.post(
              httpService.apiEndpoint +
                "rest/payment_method/payments&store_id=0",
              {
                payment_method: "pumbolt",
                agree: 1,
                comment: "string2",
              },
              { headers: { ...httpService.headers, "X-Oc-Session": sessionId } }
            );
            response = response.data;
            if (
              response &&
              "success" in response &&
              response.success === 1 &&
              "error" in response &&
              response.error.length === 0
            ) {
              // console.log("payment method created");

              let response = await httpService.post(
                httpService.apiEndpoint + "rest/confirm/confirm&store_id=0",
                {},
                {
                  headers: {
                    ...httpService.headers,
                    "X-Oc-Session": sessionId,
                    "X-Oc-Currency": currency,
                  },
                }
              );
              response = response.data;

              const payUData = response.payu_info;
              if (payUData) {
                let pd = {
                  key: payUData.key,
                  txnid: payUData.txnid,
                  amount: payUData.amount,
                  firstname: payUData.firstname,
                  email: payUData.email,
                  phone: payUData.phone,
                  productinfo: payUData.productinfo,
                  surl: payUData.surl,
                  furl: payUData.furl,
                  hash: payUData.hash,
                  Pg: payUData.Pg,
                };
                setPayUForm(pd);
              }
              const payPalData = response.paypal;
              if (payPalData) {
                let ppd = {
                  action: payPalData.action,
                  business: payPalData.business,
                  currency_code: payPalData.currency_code,
                  first_name: payPalData.first_name,
                  last_name: payPalData.last_name,
                  address1: payPalData.address1,
                  address2: payPalData.address2,
                  city: payPalData.city,
                  zip: payPalData.zip,
                  country: payPalData.country,
                  email: payPalData.email,
                  lc: payPalData.lc,
                  return: payPalData.return,
                  notify_url: payPalData.notify_url,
                  cancel_return: payPalData.cancel_return,
                  custom: payPalData.custom,
                  discount_amount_cart: payPalData.discount_amount_cart,
                  products: payPalData.products,
                };
                setPayPalForm(ppd);
              }
              if (
                response &&
                "success" in response &&
                response.success === 1 &&
                "error" in response &&
                response.error.length === 0
              ) {
                // console.log("Order confirmation created");
                setCheckout(response.data);
                setCheckoutStep("confirmorder");
                setButtonsDisabled(false);
              } else if (
                response &&
                "success" in response &&
                response.success !== 1 &&
                "error" in response &&
                response.error.length !== 0
              ) {
                // console.log(
                //   "confirm order unsuccessful due to errors!",
                //   response.error[0]
                // );
                showToast("error", response.error[0]);
                setButtonsDisabled(false);
              } else {
                // console.log("confirm order unsucessful due to unknown!");
                // showToast("error", "Something went wrong!");
                setButtonsDisabled(false);
                navigate(-1);
              }

              setCheckoutStep("confirmorder");
              setButtonsDisabled(false);
            } else if (
              response &&
              "success" in response &&
              response.success !== 1 &&
              "error" in response &&
              response.error.length !== 0
            ) {
              // console.log(
              //   "payment method unsuccessful due to errors!",
              //   response.error[0]
              // );
              showToast("error", response.error[0]);
              setButtonsDisabled(false);
            } else {
              // console.log("payment method unsucessful due to unknown!");
              // showToast("error", "Something went wrong!");
              navigate(-1);
              setButtonsDisabled(false);
            }
          } else if (
            response &&
            "success" in response &&
            response.success !== 1 &&
            "error" in response &&
            response.error.length !== 0
          ) {
            // console.log(
            //   "shipping method unsuccessful due to errors!",
            //   response.error[0]
            // );
            showToast("error", response.error[0]);
            setButtonsDisabled(false);
          } else {
            // console.log("shipping method unsucessful due to unknown!");
            // ("error", "Something went wrong!");
            navigate(-1);
            setButtonsDisabled(false);
          }
        } else if (
          response &&
          "success" in response &&
          response.success !== 1 &&
          "error" in response &&
          response.error.length !== 0
        ) {
          // console.log(
          //   "guest shipping unsucessful due to errors!",
          //   response.error[0]
          // );
          showToast("error", response.error[0]);
          setButtonsDisabled(false);
        } else {
          // console.log("guest shipping unsucessful due to unknown!");
          // showToast("error", "Something went wrong!");
          navigate(-1);
          setButtonsDisabled(false);
        }
      } catch (error) {
        // console.log("error", error);
        setButtonsDisabled(false);
        const expectedError =
          error.response &&
          error.response.status >= 400 &&
          error.response.status < 500;

        if (expectedError) {
          showToast("error", error.response.data.error[0]);
          // console.log("create shipping failed", error.response);
        } else {
          // showToast("error", "Something went wrong!");
          navigate(-1);
          // console.log("create shipping failed", error);
        }
      }
    }
  };

  const confirmPaymentAddress = async () => {
    setButtonsDisabled(true);
    const keys = Object.keys(existingPayment);
    let check = true;
    for (let i = 0; i < keys.length; i++) {
      if (
        existingPayment[keys[i]] === "" ||
        existingPayment[keys[i]] === null
      ) {
        showToast("error", `Please select an address`);
        check = false;
        setButtonsDisabled(false);
        return false;
      }
    }
    if (check) {
      try {
        let response = await httpService.post(
          httpService.apiEndpointShort + "paymentaddress/existing",
          existingPayment,
          { headers: { ...httpService.headers, "X-Oc-Session": sessionId } }
        );
        response = response.data;
        // console.log("select paymet address response-", response);
        if (
          response &&
          "success" in response &&
          response.success === 1 &&
          "error" in response &&
          response.error.length === 0
        ) {
          // console.log("select paymet address success");
          setCheckoutStep("shippingaddress");
          setZones([]);
          setButtonsDisabled(false);
        } else if (
          response &&
          "success" in response &&
          response.success !== 1 &&
          "error" in response &&
          response.error.length !== 0
        ) {
          // console.log(
          //   "select paymet address unsucessful due to errors!",
          //   response.error[0]
          // );
          showToast("error", response.error[0]);
          setButtonsDisabled(false);
        } else {
          // console.log("select paymet address unsucessful due to unknown!");
          // showToast("error", "Something went wrong!");
          navigate(-1);
          setButtonsDisabled(false);
        }
      } catch (error) {
        // console.log("error", error);
        setButtonsDisabled(false);
        const expectedError =
          error.response &&
          error.response.status >= 400 &&
          error.response.status < 500;

        if (expectedError) {
          showToast("error", error.response.data.error[0]);
          // console.log("select paymet address failed", error.response);
        } else {
          // showToast("error", "Something went wrong!");
          navigate(-1);
          // console.log("select paymet address failed", error);
        }
      }
    }
  };

  const confirmShippingAddress = async () => {
    setButtonsDisabled(true);
    const keys = Object.keys(existingShipping);
    let check = true;
    for (let i = 0; i < keys.length; i++) {
      if (
        existingShipping[keys[i]] === "" ||
        existingShipping[keys[i]] === null
      ) {
        showToast("error", `Please select a shiping address!`);
        check = false;
        setButtonsDisabled(false);
        return false;
      }
    }
    if (check) {
      try {
        let response = await httpService.post(
          httpService.apiEndpointShort + "shippingaddress/existing",
          existingShipping,
          { headers: { ...httpService.headers, "X-Oc-Session": sessionId } }
        );
        response = response.data;
        // console.log("select shipping response-", response);
        if (
          response &&
          "success" in response &&
          response.success === 1 &&
          "error" in response &&
          response.error.length === 0
        ) {
          // console.log("select shipping created");

          let { data: shippingMethods } = await httpService.get(
            httpService.apiEndpoint +
              "rest/shipping_method/shippingmethods&store_id=0",
            { headers: { ...httpService.headers, "X-Oc-Session": sessionId } }
          );

          if (
            "error" in shippingMethods &&
            shippingMethods.error.length !== 0
          ) {
            throw new Error(400, shippingMethods.error[0]);
          }
          const shm = shippingMethods.data.shipping_methods[0].quote[0].code;
          let response = await httpService.post(
            httpService.apiEndpoint +
              "rest/shipping_method/shippingmethods&store_id=0",
            {
              shipping_method: shm,
              comment: "string3",
            },
            { headers: { ...httpService.headers, "X-Oc-Session": sessionId } }
          );
          response = response.data;
          if (
            response &&
            "success" in response &&
            response.success === 1 &&
            "error" in response &&
            response.error.length === 0
          ) {
            // console.log("shipping method created");

            let { data: paymentMethods } = await httpService.get(
              httpService.apiEndpoint +
                "rest/payment_method/payments&store_id=0",
              { headers: { ...httpService.headers, "X-Oc-Session": sessionId } }
            );

            if (
              "error" in paymentMethods &&
              paymentMethods.error.length !== 0
            ) {
              throw new Error(400, paymentMethods.error[0]);
            }

            let response = await httpService.post(
              httpService.apiEndpoint +
                "rest/payment_method/payments&store_id=0",
              {
                payment_method: "pumbolt",
                agree: 1,
                comment: commentt,
              },
              { headers: { ...httpService.headers, "X-Oc-Session": sessionId } }
            );
            response = response.data;
            if (
              response &&
              "success" in response &&
              response.success === 1 &&
              "error" in response &&
              response.error.length === 0
            ) {
              // console.log("payment method created");

              let response = await httpService.post(
                httpService.apiEndpoint + "rest/confirm/confirm&store_id=0",
                {},
                {
                  headers: {
                    ...httpService.headers,
                    "X-Oc-Session": sessionId,
                    "X-Oc-Currency": currency,
                  },
                }
              );
              response = response.data;

              const payUData = response.payu_info;
              if (payUData) {
                let pd = {
                  key: payUData.key,
                  txnid: payUData.txnid,
                  amount: payUData.amount,
                  firstname: payUData.firstname,
                  email: payUData.email,
                  phone: payUData.phone,
                  productinfo: payUData.productinfo,
                  surl: payUData.surl,
                  furl: payUData.furl,
                  hash: payUData.hash,
                  Pg: payUData.Pg,
                };
                setPayUForm(pd);
              }
              const payPalData = response.paypal;
              if (payPalData) {
                let ppd = {
                  action: payPalData.action,
                  business: payPalData.business,
                  currency_code: payPalData.currency_code,
                  first_name: payPalData.first_name,
                  last_name: payPalData.last_name,
                  address1: payPalData.address1,
                  address2: payPalData.address2,
                  city: payPalData.city,
                  zip: payPalData.zip,
                  country: payPalData.country,
                  email: payPalData.email,
                  lc: payPalData.lc,
                  return: payPalData.return,
                  notify_url: payPalData.notify_url,
                  cancel_return: payPalData.cancel_return,
                  custom: payPalData.custom,
                  discount_amount_cart: payPalData.discount_amount_cart,
                  products: payPalData.products,
                };
                setPayPalForm(ppd);
              }

              if (
                response &&
                "success" in response &&
                response.success === 1 &&
                "error" in response &&
                response.error.length === 0
              ) {
                // console.log("Order confirmation created");
                setCheckout(response.data);
                setCheckoutStep("confirmorder");
                setButtonsDisabled(false);
              } else if (
                response &&
                "success" in response &&
                response.success !== 1 &&
                "error" in response &&
                response.error.length !== 0
              ) {
                // console.log(
                //   "confirm order unsuccessful due to errors!",
                //   response.error[0]
                // );
                showToast("error", response.error[0]);
                setButtonsDisabled(false);
              } else {
                // console.log("confirm order unsucessful due to unknown!");
                // showToast("error", "Something went wrong!");
                navigate(-1);
                setButtonsDisabled(false);
              }

              setCheckoutStep("confirmorder");
              setButtonsDisabled(false);
            } else if (
              response &&
              "success" in response &&
              response.success !== 1 &&
              "error" in response &&
              response.error.length !== 0
            ) {
              // console.log(
              //   "payment method unsuccessful due to errors!",
              //   response.error[0]
              // );
              showToast("error", response.error[0]);
              setButtonsDisabled(false);
            } else {
              // console.log("payment method unsucessful due to unknown!");
              // showToast("error", "Something went wrong!");
              navigate(-1);
              setButtonsDisabled(false);
            }
          } else if (
            response &&
            "success" in response &&
            response.success !== 1 &&
            "error" in response &&
            response.error.length !== 0
          ) {
            // console.log(
            //   "shipping method unsuccessful due to errors!",
            //   response.error[0]
            // );
            showToast("error", response.error[0]);
            setButtonsDisabled(false);
          } else {
            // console.log("shipping method unsucessful due to unknown!");
            // showToast("error", "Something went wrong!");
            navigate(-1);
            setButtonsDisabled(false);
          }
        } else if (
          response &&
          "success" in response &&
          response.success !== 1 &&
          "error" in response &&
          response.error.length !== 0
        ) {
          // console.log(
          //   "guest shipping unsucessful due to errors!",
          //   response.error[0]
          // );
          showToast("error", response.error[0]);
          setButtonsDisabled(false);
        } else {
          // console.log("guest shipping unsucessful due to unknown!");
          // showToast("error", "Something went wrong!");
          navigate(-1);
          setButtonsDisabled(false);
        }
      } catch (error) {
        // console.log("error", error);
        setButtonsDisabled(false);
        const expectedError =
          error.response &&
          error.response.status >= 400 &&
          error.response.status < 500;

        if (expectedError) {
          showToast("error", error.response.data.error[0]);
          // console.log("create shipping failed", error.response);
        } else {
          // showToast("error", "Something went wrong!");
          navigate(-1);
          // console.log("create shipping failed", error);
        }
      }
    }
  };

  const selectAddress = (address) => {
    console.log("selecting address");
    setSelectedPaymentAddress(address.address_id);
    setPayment({
      firstname: address.firstname,
      lastname: address.lastname,
      city: address.city,
      address_1: address.address_1,
      address_2: address.address_2,
      country_id: address.country_id,
      postcode: address.postcode,
      zone_id: address.zone_id,
      company: address.company,
    });

    setShipping({
      firstname: address.firstname,
      lastname: address.lastname,
      city: address.city,
      address_1: address.address_1,
      address_2: address.address_2,
      country_id: address.country_id,
      postcode: address.postcode,
      zone_id: address.zone_id,
      company: address.company,
    });

    setExistingPayment({ address_id: address.address_id });
    setExistingShipping({ address_id: address.address_id });
  };

  return (
    <>
      <Header />
      {cart && cart.length !== 0 ? (
        <React.Fragment>
          <div>
            {checkoutStep === "createguest" ? (
              <>
                <Billing
                  handleChange={handleChange}
                  guestUser={guestUser}
                  setGuestUser={setGuestUser}
                  handleCountryChange={handleCountryChange}
                  handleZoneChange={handleZoneChange}
                  countries={countries}
                  zones={zones}
                  createGuestUser={createGuestUser}
                  buttonsDisabled={buttonsDisabled}
                  cc={cc}
                  handleCountryCodeChange={handleCountryCodeChange}
                ></Billing>
              </>
            ) : null}

            {checkoutStep === "guestshipping" ? (
              <>
                <Shipping
                  handleChange={handleChange}
                  shipping={shipping}
                  setShipping={setShipping}
                  handleCountryChange={handleCountryChange}
                  handleZoneChange={handleZoneChange}
                  countries={countries}
                  zones={zones}
                  createShipping={createShipping}
                  buttonsDisabled={buttonsDisabled}
                  replicateAddress={replicateAddress}
                  cc={cc}
                  handleCountryCodeChange={handleCountryCodeChange}
                ></Shipping>
              </>
            ) : null}

            {checkoutStep === "paymentaddress" ? (
              <AddressBook
                heading="Select Payment Address"
                addresses={addresses}
                selectAddress={selectAddress}
                selectedAddress={selectedPaymentAddress}
                confirmAddress={confirmPaymentAddress}
                buttonsDisabled={buttonsDisabled}
              />
            ) : null}

            {checkoutStep === "shippingaddress" ? (
              <AddressBook
                heading="Select Shipping Address"
                addresses={addresses}
                selectAddress={selectAddress}
                selectedAddress={selectedPaymentAddress}
                confirmAddress={confirmShippingAddress}
                buttonsDisabled={buttonsDisabled}
              />
            ) : null}

            {checkoutStep === "paymentmethod" ? (
              <>
                <div style={{ padding: "20px 10px" }}>
                  <div className="d-flex align-items-center justify-content-between">
                    <h6 style={{ color: "#565656" }} className="m-0  p-0">
                      Payment Method
                    </h6>
                  </div>
                </div>

                <div
                  style={{
                    background: "#fff",
                    padding: "20px 20px 30px 20px",
                    margin: "0 5px",
                  }}
                >
                  <div className="form-check">
                    <input
                      onChange={(e) => handleChange(e)}
                      className="form-check-input"
                      type="radio"
                      name="payment-method"
                      id=""
                      defaultChecked={true}
                    ></input>
                    <label className="form-check-label" htmlFor="">
                      Cash on Delivery
                    </label>
                  </div>
                  {/* <div className="form-check">
                        <input onChange={(e) => handleChange(e)}  className="form-check-input" type="radio" name="payment-method" id=""></input>
                        <label className="form-check-label" htmlFor="">
                            UPI
                        </label>
                    </div> */}
                </div>
              </>
            ) : null}

            {checkoutStep === "confirandpay" ? (
              <>
                <div style={{ padding: "20px 10px" }}>
                  <div className="d-flex align-items-center justify-content-between">
                    <h5 style={{ color: "#565656" }} className="m-0  p-0">
                      Total
                    </h5>
                    <h4 style={{ color: "#5956E9" }} className="m-0  p-0">
                      {"₹" +
                        _.sum(cart.map((cartItem) => cartItem.total))
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </h4>
                  </div>
                </div>
                <div style={{ margin: "0 5px" }}>
                  <button
                    onClick={(e) => placeOrder(e)}
                    className="confirm-and-pay-btn"
                    disabled={buttonsDisabled}
                  >
                    {buttonsDisabled ? "Order Placing..." : "Confirm and Pay"}
                  </button>
                </div>
              </>
            ) : null}

            {checkoutStep === "confirmorder" ? (
              <>
                <Confirm
                  checkout={checkout}
                  placeOrder={placeOrder}
                  buttonsDisabled={buttonsDisabled}
                  handlePaymentChange={handlePaymentChange}
                  payUForm={payUForm}
                  setPayUForm={setPayUForm}
                  payPalForm={payPalForm}
                  payment={guestUser}
                />
              </>
            ) : null}

            {checkoutStep === "pending" ? (
              <>
                <Loading></Loading>
              </>
            ) : null}
          </div>

          {/* <Footer></Footer> */}
        </React.Fragment>
      ) : (
        <Loading></Loading>
      )}
      <FooterDesktop />
    </>
  );
};

export default Checkout;
